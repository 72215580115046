import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useCustomStyles = makeStyles()((theme) => ({
	tag: {
		display: 'grid',
		minWidth: theme.spacing.small,
		gridTemplateRows: 'auto',
		gridTemplateColumns: 'auto 0fr',
		marginRight: theme.spacing.xxsmall,

		'& > span': {
			whiteSpace: 'nowrap',
		},
	},
	days: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
}));
