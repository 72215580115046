import { useCallback, useEffect, FC, ReactElement, useState, useMemo } from 'react';

import { Icon, IconTypes } from '@vakantiesnl/components/src/atoms/Icon';
import useFormStyles from '@vakantiesnl/components/src/styles/forms.style';
import { useIsMobile } from '@vakantiesnl/services/src/hooks/useMediaQuery';
import { getDateDMY, getMinDepartureDate, getMaxDepartureDate } from '@vakantiesnl/services/src/util/dateUtils';
import { Dutch } from 'flatpickr/dist/l10n/nl.js';
import Flatpickr, { DateTimePickerProps, Omit } from 'react-flatpickr';

import globalStyles from './flatpickr.global.css';
import { useStyles } from './PickDepartureDate.style';
import { staticYearMonthPlugin } from './plugins/staticYearMonth';

const getWeekNumber = (givenDate: Date): string => {
	const date = new Date(givenDate.getTime());
	date.setHours(0, 0, 0, 0);

	// Thursday in current week decides the year.
	date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));

	// January 4 is always in week 1.
	const week1 = new Date(date.getFullYear(), 0, 4);

	// Adjust to Thursday in week 1 and count number of weeks from date to week1.
	return `wk ${1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7)}`;
};

const getOptions = (defaultDate: Date | undefined, isMobile: boolean): Record<string, unknown> => ({
	utc: true,
	weekNumbers: !isMobile,
	locale: Dutch,
	dateFormat: 'd-m-Y',
	altFormat: 'd-m-Y',
	ariaDateFormat: 'd-m-Y',
	animate: false,
	minDate: getMinDepartureDate().toDate(),
	maxDate: getMaxDepartureDate().toDate(),
	plugins: [staticYearMonthPlugin()],
	position: 'below',
	monthSelectorType: 'static',
	allowInput: false,
	disableMobile: true,
	defaultDate,
	getWeek: getWeekNumber,
	prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path d="M12.432 17.685l-5.57-5.56L5.446 10.7c-.39-.39-.39-1.022 0-1.412l1.416-1.413 5.57-5.56c.39-.388 1.02-.388 1.41 0l.71.71c.388.39.388 1.02 0 1.41l-5.57 5.57 5.57 5.56c.388.39.388 1.02 0 1.41l-.71.71c-.39.388-1.02.388-1.41 0z"/>
</svg>
`,
	nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path d="M7.568 17.685l5.57-5.56 1.416-1.425c.39-.39.39-1.022 0-1.412l-1.416-1.413-5.57-5.56c-.39-.388-1.02-.388-1.41 0l-.71.71c-.388.39-.388 1.02 0 1.41l5.57 5.57-5.57 5.56c-.388.39-.388 1.02 0 1.41l.71.71c.39.388 1.02.388 1.41 0z"/>
</svg>
`,
});

export type PickDepartureDateProps = {
	labelText?: string;
	placeholder?: string;
	onChange: (date: Date) => void;
	departureDate?: Date;
	className?: string;
	iconRight?: IconTypes;
	iconLeft?: IconTypes;
	cyAttribute?: string;
	onInputOpen?: (moleculeName: 'quicksearch' | 'datepicker' | 'duration') => void;
};

const CustomInput: FC<
	Omit<DateTimePickerProps, 'options' | 'render'> & {
		inputRef: (node: HTMLInputElement) => void;
		iconRight: IconTypes;
		iconLeft?: IconTypes;
	}
> = ({ value, placeholder, inputRef, iconRight, iconLeft }) => {
	const { classes: styles, cx } = useStyles();

	return (
		<div className={cx(styles.inputContainer, iconLeft && styles.hasIconLeft)}>
			{iconLeft && (
				<div className={cx(styles.iconContainer, styles.iconLeft)}>
					<Icon type={iconLeft} />
				</div>
			)}
			<input
				className={styles.flatpickrInput}
				data-cy="flatpickrInput"
				ref={inputRef}
				placeholder={placeholder}
				defaultValue={value as string}
			/>
			<div className={cx(styles.iconContainer, styles.iconRight)}>
				<Icon type={iconRight} />
			</div>
		</div>
	);
};

export const PickDepartureDate: FC<PickDepartureDateProps> = ({
	labelText,
	placeholder,
	onChange,
	departureDate,
	className,
	iconRight,
	iconLeft,
	cyAttribute,
	onInputOpen,
}) => {
	const { classes: styles, cx } = useStyles();
	const { classes: formStyles } = useFormStyles();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [baseDate] = useState<Date | undefined>(departureDate);
	const isMobile = useIsMobile();
	const handleOnChange = useCallback(
		(date: Date[]): void => {
			if (date.length > 0) {
				onChange(date[0]);
			}
		},
		[onChange],
	);
	const onOpen = useCallback((): void => {
		setIsOpen(true);
		if (onInputOpen) {
			// Tracking "open" action
			onInputOpen('datepicker');
		}
	}, [setIsOpen, onInputOpen]);
	const onClose = useCallback((): void => {
		setIsOpen(false);
	}, [setIsOpen]);

	const options = useMemo(() => getOptions(baseDate, isMobile), [isMobile, baseDate]);

	const renderInput = useCallback(
		(
			props: Omit<DateTimePickerProps, 'options' | 'render'>,
			ref: (node: HTMLInputElement) => void,
		): ReactElement => {
			return (
				<CustomInput
					{...props}
					inputRef={ref}
					iconLeft={iconLeft}
					iconRight={iconRight ? iconRight : isOpen ? 'chevronUp' : 'chevronDown'}
				/>
			);
		},
		[isOpen, iconRight, iconLeft],
	);

	useEffect(() => {
		globalStyles.use();
		return (): void => {
			globalStyles.unuse();
		};
	}, []);

	return (
		<fieldset
			className={cx(formStyles.inputFieldset, styles.container, labelText && formStyles.hasLabel, className)}
			data-cy={cyAttribute}
		>
			{labelText && <legend className={formStyles.inputLegend}>{labelText}</legend>}
			<Flatpickr
				options={options}
				defaultValue={baseDate ? getDateDMY(baseDate) : undefined}
				value={departureDate ? getDateDMY(departureDate) : undefined}
				onChange={handleOnChange}
				onOpen={onOpen}
				onClose={onClose}
				placeholder={placeholder}
				render={renderInput}
			/>
		</fieldset>
	);
};
