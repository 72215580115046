import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useStyles = makeStyles<void, 'flatpickrInput'>()((theme, _, classes) => ({
	container: {
		position: 'relative',
		zIndex: 2,

		[`& .${classes.flatpickrInput}`]: {
			border: 'none',
			borderRadius: theme.borderRadius.rounded,
			boxSizing: 'border-box',
			display: 'block',
			fontFamily: theme.fontFamily.primary,
			fontSize: theme.fontSize.xSmall,
			outline: 'none',
			padding: `${theme.spacing.xsmall} ${theme.spacing.small}`,
			width: '100%',
			background: 'transparent',
			color: theme.palette.neutral['60'],
		},
	},
	hasIconLeft: {
		[`& .${classes.flatpickrInput}`]: {
			padding: `13px 0 13px ${theme.spacing.xxlarge}`,
		},
	},
	flatpickrInput: {},
	inputContainer: {
		position: 'relative',
		width: '100%',

		'& input[type="date"]': {
			display: 'block',
			appearance: 'none',
			minHeight: 1.2,
		},
	},
	iconContainer: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		pointerEvents: 'none',
	},
	iconLeft: {
		left: theme.spacing.small,
	},
	iconRight: {
		right: theme.spacing.small,
	},
}));
