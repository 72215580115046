import { FC } from 'react';

import { getConsecutiveDayRanges } from '@vakantiesnl/components/src/utils/consecutiveDayRanges';
import { MicroCopy } from '@vakantiesnl/types/src';

import { useCustomStyles } from './DurationsTagName.styles';

export const DurationsTagName: FC<{
	durations: number[];
	microCopies: MicroCopy;
}> = ({ durations, microCopies }) => {
	const { classes } = useCustomStyles();

	return (
		<span className={classes.tag}>
			<span className={classes.days}>{getConsecutiveDayRanges(durations).join('; ')}</span>
			<span>&zwnj; {microCopies['common.days']}</span>
		</span>
	);
};
