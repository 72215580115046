import { FC, useCallback, useState } from 'react';

import { Icon } from '@vakantiesnl/components/src/__LEGACY__/atoms/Icon';
import { OptionBox, OptionProp } from '@vakantiesnl/components/src/__LEGACY__/atoms/SelectBox';
import { useStyles } from '@vakantiesnl/components/src/__LEGACY__/atoms/SelectBox/SelectBox.style';
import useFormsStyles from '@vakantiesnl/components/src/styles/forms.style';
import { MicroCopy } from '@vakantiesnl/types';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import OptGroup from 'rc-select/lib/OptGroup';
import Option from 'rc-select/lib/Option';
import Select from 'rc-select/lib/Select';
import { GlobalStyles } from 'tss-react';

export type OptionGroup = Array<{ title: string; options: OptionProp[] }>;

type Props = {
	optionGroup: OptionGroup;
	placeHolderText: string;
	microCopies: MicroCopy;
	onSelect: (option: string) => void;
	onDeselect: (option: string) => void;
};

const dropdownAlign = { offset: [0, 16] };

export const GroupSelectBox: FC<Props> = ({ microCopies, onDeselect, onSelect, placeHolderText, optionGroup }) => {
	const { classes: styles, cx, theme } = useStyles();
	const { classes: formStyles } = useFormsStyles();
	const [toggled, setToggled] = useState<boolean>(false);
	const [selectedValues, setSelectedValues] = useState<string[]>([]);

	const onDropdownVisibleChange = useCallback((): void => {
		setToggled(!toggled);
	}, [toggled, setToggled]);

	const renderOptionGroup = useCallback(
		({ title, options }: { title: string; options: OptionProp[] }): JSX.Element => {
			return (
				<OptGroup label={title} key={title}>
					{options.map((item: OptionProp) => (
						<Option key={item.value} value={item.value} className={cx(styles.option, styles.multiple)}>
							<OptionBox item={item} checked={selectedValues.includes(item.value)} mode="multiple" />
						</Option>
					))}
				</OptGroup>
			);
		},
		[cx, selectedValues, styles.multiple, styles.option],
	);

	const tagRender = useCallback(
		(customTagProps: CustomTagProps) => {
			const { label } = customTagProps;
			const selectedCount = selectedValues.length;
			const title = optionGroup
				.map(({ options }) => options)
				// flatten
				.reduce((acc, val) => acc.concat(val), [])
				.find(({ value }) => selectedValues[0] === value)?.title;
			const name =
				selectedCount === 1 && title ? title : `${selectedCount} ${microCopies['common.optionsChosen']}`;

			return (
				<span className={styles.option} onClick={onDropdownVisibleChange}>
					{label && name}
				</span>
			);
		},
		[microCopies, onDropdownVisibleChange, optionGroup, selectedValues, styles.option],
	);

	const onDeselectOption = useCallback(
		(option: string) => {
			const filteredSelectedValues = selectedValues.filter((item) => option !== item);
			setSelectedValues(filteredSelectedValues);
			onDeselect(option);
		},
		[onDeselect, selectedValues],
	);

	const onSelectOption = useCallback(
		(option: string) => {
			setSelectedValues([...selectedValues, option]);
			onSelect(option as string);
		},
		[onSelect, selectedValues],
	);

	return (
		<div>
			<GlobalStyles
				// eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
				styles={{
					['.rc-select-item-group']: {
						display: 'flex',
						alignItems: 'center',
						padding: theme.spacing(1.75, 2),
						color: theme.palette?.primary?.main,
						fontFamily: theme.typography.subtitle1.fontFamily,
						fontSize: theme.spacing(1.75),
					},
				}}
			/>
			<fieldset className={cx(formStyles.inputFieldset, styles.selectBox)}>
				<Select
					className={styles.selectBoxRoot}
					placeholder={placeHolderText}
					open={toggled}
					onDropdownVisibleChange={onDropdownVisibleChange}
					menuItemSelectedIcon={null}
					dropdownClassName={styles.dropdown}
					showArrow={true}
					mode="multiple"
					onSelect={onSelectOption}
					onDeselect={onDeselectOption}
					dropdownAlign={dropdownAlign}
					maxTagCount={1}
					maxTagPlaceholder={''}
					tagRender={tagRender}
					inputIcon={
						<Icon type={toggled ? 'vaknlChevronUp' : 'vaknlChevronDown'} className={styles.selectBoxIcon} />
					}
					virtual={false}
				>
					{optionGroup && optionGroup.map(renderOptionGroup)}
				</Select>
			</fieldset>
		</div>
	);
};
