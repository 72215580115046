import flatpickr from 'flatpickr';
type Locale = flatpickr.Locale;
type Plugin = flatpickr.Options.Plugin;
type Options = flatpickr.Options.Options;
type Instance = flatpickr.Instance;

export const monthToStr = (monthNumber: number, shorthand: boolean, locale: Locale): string =>
	locale.months[shorthand ? 'shorthand' : 'longhand'][monthNumber];

export function staticYearMonthPlugin(): Plugin {
	return (fp: Instance): Options => {
		function staticYearMonth(): void {
			if (!fp.monthNav) return;

			const selector = fp.monthNav.querySelector('.flatpickr-current-month .cur-month');
			if (selector && selector.parentNode) {
				selector.parentNode.removeChild(selector);
			}
			const selectorInput = fp.monthNav.querySelector('.flatpickr-current-month .numInputWrapper');
			if (selectorInput && selectorInput.parentNode) {
				selectorInput.parentNode.removeChild(selectorInput);
			}
			const monthElement = window.document.createElement('span');
			monthElement.className = 'cur-month';
			monthElement.textContent =
				monthToStr(fp.currentMonth, fp.config.shorthandCurrentMonth, fp.l10n) + ' ' + fp.currentYear;
			const monthItem = fp.monthNav.querySelector('.flatpickr-current-month');
			if (monthItem) {
				monthItem.appendChild(monthElement);
			}
		}

		return {
			onOpen: staticYearMonth,
			onMonthChange: staticYearMonth,
			onYearChange: staticYearMonth,
			onChange: staticYearMonth,
			onValueUpdate: staticYearMonth,
			onReady: [
				staticYearMonth,
				(): void => {
					fp.loadedPlugins.push('staticYearMonth');
				},
			],
		};
	};
}
